<template>
    <v-card>
        <v-card-title>
            <span>Factura de compra</span>
            <v-spacer></v-spacer>
            <v-btn v-if="modal == true" icon @click="cerrarModal()">
                <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="cargando" class="text-center">
            <h4>Buscando la Factura de compra...</h4>
        </v-card-text>
        <v-card-text v-if="!cargando && datos">

            <v-row>
                <v-col lg="8" md="8" sm="12" cols="12">
                    <CabeceraEmpresa></CabeceraEmpresa>
                </v-col>
                <v-col lg="4" md="4" sm="12" cols="12">
                    <h2 class="pt-12 pb-2"> #
                        <small>{{ datos.numeroDocumento }} </small>
                    </h2>
                    <span class="m-0 p-0"> Fecha creacion: {{
                        FuncionesGenerales.formatoFecha(datos.fechaCreacion, 3)
                    }} </span> 
                    <br>
                    <span class="m-0 p-0"> Empleado: Nombre Apellido</span>
                    <br>
                    <span class="m-0 p-0"> Autorizado: Nombre Apellido</span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="mt-4 mb-2">
                <v-col  lg="8" md="8" sm="12" cols="12">
                    <span class="m-0 p-0"> Area: {{ datos.area.nombre }}</span>
                    <br>
                    <span class="m-0 p-0"> Moneda Negociacion: {{ datos.moneda.nombre }}</span>
                    <br>
                    <span class="m-0 p-0"> Condicion Pago: {{ datos.condicionPago.descripcion }}</span>
                    <br>
                    <span class="m-0 p-0"> Proveedor: {{ datos.proveedor.razonSocial }}</span>
                    <br>
                    <h4 class="m-0 p-0"> Estatus: {{ datos.estatus.descripcion }}</h4>

                </v-col>
                <v-col lg="4" md="4" sm="12" cols="12">
                    <span class="m-0 p-0"> Numero Factrua: {{ datos.numeroFactura }}</span>
                    <br>
                    <span class="m-0 p-0"> Numero Control: {{ datos.NumeroControl }}</span>
                    <br>
                    <span class="m-0 p-0"> Fecha Factura: {{
                        FuncionesGenerales.formatoFecha(datos.fechaFactura, 1)
                    }} </span>
                    
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
                <v-col cols="12">
                    <v-simple-table dense>
                        <template v-slot:default>

                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Producto
                                    </th>
                                    <th class="text-left">
                                        Lote
                                    </th>
                                    <th class="text-right">
                                        Cant
                                    </th>
                                    <th class="text-right">
                                        Precio
                                    </th>
                                    <th class="text-right">
                                        Descuento
                                    </th>
                                    <th class="text-right">
                                        Iva
                                    </th>
                                    <th class="text-right">
                                        Total
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, i) in datos.compraDetalle" :key="i">
                                    <td>
                                        <small> {{
                                            item.productoSucursal.producto.barra
                                        }} </small>
                                        <br>
                                        {{ item.productoSucursal.producto.nombre }} 
                                    </td>
                                    <td>
                                      <span v-if="item.lote">{{ item.lote.numeroLote }}</span>  
                                    </td>
                                    <td class="text-right">
                                        {{ item.cantidad }}
                                    </td>
                                    <td class="text-right">
                                        {{ item.monto }}

                                    </td>
                                    <td class="text-right">

                                        {{ item.descuento }}
                                    </td>
                                    <td class="text-right">

                                        {{ item.montoIVA }}
                                    </td>
                                    <td class="text-right">

                                        {{ item.total }}
                                    </td>
                                </tr>
                            </tbody>


                        </template>
                    </v-simple-table>
                </v-col>
                <v-col cols="12" sm="6" md="8" lg="8">

                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4">

                    <h5 class="ml-4">Totales </h5>

                    <v-simple-table dense>
                        <template>
                            <tbody>
                                <tr>
                                    <td>Sub Total</td>
                                    <td class="text-right">
                                        <span> {{ FuncionesGenerales.monedaPrincipal.simbolo }}</span> {{
                                            FuncionesGenerales.roundToTwo(datos.subTotal, 2).toFixed(2)
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Descuento</td>
                                    <td class="text-right">
                                        <span> {{ FuncionesGenerales.monedaPrincipal.simbolo }}</span> {{
                                            FuncionesGenerales.roundToTwo(datos.descuento, 2).toFixed(2)
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Impuesto</td>
                                    <td class="text-right">
                                        <span> {{ FuncionesGenerales.monedaPrincipal.simbolo }}</span> {{
                                            FuncionesGenerales.roundToTwo(datos.impuesto, 2).toFixed(2)
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4><span class="primary--text">Total Factura</span></h4>
                                    </td>
                                    <td class="text-right">
                                        <h4>
                                            <span class="primary--text">

                                                <span> {{ FuncionesGenerales.monedaPrincipal.simbolo }}</span>
                                                {{
                                                    FuncionesGenerales.roundToTwo(datos.total, 2).toFixed(2)
                                                }}
                                            </span>
                                        </h4>
                                    </td>
                                </tr>
                            </tbody>
                        </template>

                    </v-simple-table>
                </v-col>
            </v-row>

            <v-divider></v-divider>

        </v-card-text>
        <v-card-actions>
            <v-row>
                <v-col cols="12" class="text-center mt-5">

                    <BtnPdf :id="IdDocumento" :tipoBtn="1"></BtnPdf>
                    <v-btn v-if="VerButton == true" color="primary" class="ml-2" small :disabled="datos.idEstatus != 10"
                        @click="GetEditar(datos)">

                        <v-icon left small>
                            {{ icons.mdiPencil }}
                        </v-icon>
                        Editar
                    </v-btn>
                    <v-btn v-if="VerButton == true" color="primary" class="ml-2" small :disabled="datos.idEstatus != 10"
                        @click="GetFinalizar(datos)">
                        <v-icon left small>
                            {{ icons.mdiCheck }}
                        </v-icon>
                        Finalizar
                    </v-btn>
                    <v-btn v-if="VerButton == true" color="error" class="ml-2" small :disabled="datos.idEstatus == 13"
                        @click="GetAnular(datos)">
                        <v-icon left small>
                            {{ icons.mdiBlockHelper }}
                        </v-icon>Anular
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>
<script>
import CabeceraEmpresa from '@/components/CabeceraEmpresa.vue';
import FuncionesGenerales from '@/funciones/funciones';
import { onBeforeMount, ref, watch } from '@vue/composition-api';
import BtnPdf from './BtnPdf.vue';
import store from '@/store';
import { mdiClose, mdiPrinter, mdiPencil, mdiCheck, mdiBlockHelper, mdiFilePdfBox } from '@mdi/js';
import CompraServices from '@/api/servicios/CompraServices';
export default {
    components: {
        CabeceraEmpresa,
        BtnPdf
    },
    props: {
        IdDocumento: Number,
        modal: Boolean,
        VerButton: {
            type: Boolean,
            default: true
        }
    },
    setup(props, context) {
        const cargando = ref(false)
        const datos = ref(
            {}
        )
        onBeforeMount(() => {
            cargar()
        })
        watch(props, () => {
            cargar()
        })

        const cerrarModal = () => {
            context.emit('GetCerrarModal')
        }
        const cargar = () => {
            cargando.value = true
            CompraServices.ConsultarDetalles({ id: props.IdDocumento })
                .then(response => {

                    if (response.data.estatus = true) {

                        if (response.data.datos != null && response.data.datos.length > 0) {
                            console.log("ConsultarDetalles")
                            console.log(response.data.datos)
                            datos.value = response.data.datos[0]
                        } else {
                            store.commit('setAlert', {
                                message: "No hay resultado en la busqueda",
                                type: 'warning',
                            })
                        }
                    } else {
                        store.commit('setAlert', {
                            message: response.data.mensaje,
                            type: 'warning',
                        })
                    }

                })
                .catch(err => {
                    store.commit('setAlert', {
                        message: err,
                        type: 'error',
                    })
                })
                .finally(() => {
                    cargando.value = false
                })
        }

        const GetAnular = (item) => {
            context.emit('GetAnular', item)
        }
        const GetEditar = (item) => {

            context.emit('GetEditar', item)
        }
        const GetPdf = (item) => {

            context.emit('GetPdf', item)
        }
        const GetFinalizar = (item) => {
            context.emit('GetFinalizar', item)

        }

        return {
            datos,
            FuncionesGenerales,
            cerrarModal,
            cargar,
            icons: {
                mdiClose,
                mdiPrinter,
                mdiPencil,
                mdiCheck,
                mdiBlockHelper,
                mdiFilePdfBox
            },
            GetAnular,
            GetEditar,
            GetPdf,
            GetFinalizar,
            cargando
        }
    },
}
</script>
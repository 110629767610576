<template>
    <row>
        <v-btn v-if="tipoBtn == 1" color="info" small @click="GetPdf()" :loading="cargandoDatos" :disabled="!id">
            <v-icon left small>
                {{ icons.mdiFilePdfBox }}
            </v-icon>
            PDF
        </v-btn>

        <v-tooltip bottom v-else>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="primary" v-bind="attrs" :loading="cargandoDatos"  v-on="on" @click="GetPdf()">
                    <v-icon  >{{ icons.mdiFilePdfBox }}</v-icon>
                </v-btn>
              
            </template>
            <span>PDF</span>
        </v-tooltip>
        <Pdf ref="pdfRef"></Pdf>
    </row>
</template>
<script>
import { mdiFilePdfBox } from '@mdi/js'
import { ref } from '@vue/composition-api'
import FuncionesGenerales from '@/funciones/funciones'
import Pdf from '@/components/Pdf.vue'
import CompraServices from '@/api/servicios/CompraServices'
export default {
    props: {
        id: Number,
        tipoBtn: {
            type: Number,
            default: 1
        }
    },
    components: {
        Pdf,
    },
    setup(props) {
        const cargandoDatos = ref(false)
        const pdfRef = ref(null)
        const GetPdf = () => {
            cargandoDatos.value = true

            CompraServices.ConsultarDetalles({ id: props.id })
                .then(response => {
                    console.log(response)
                    if (response.data.estatus == true) {


                        const datoInit = response.data.datos[0]
                        const cuerpoTablaDetalles = []


                        datoInit.compraDetalle.forEach(item => {
                            cuerpoTablaDetalles.push(
                                [
                                    { content: item.productoSucursal.producto.barra, styles: { halign: 'left' } },
                                    { content: item.productoSucursal.producto.nombre, styles: { halign: 'left' } },
                                    { content: item.lote ? item.lote.numeroLote : '', styles: { halign: 'left' } },
                                    { content: item.cantidad.toFixed(2), styles: { halign: 'right' } },
                                    { content: item.monto.toFixed(2), styles: { halign: 'right' } },
                                    { content: item.descuento.toFixed(2), styles: { halign: 'right' } },
                                    { content: item.montoIVA.toFixed(2), styles: { halign: 'right' } },
                                    { content: item.total.toFixed(2), styles: { halign: 'right' } },

                                ])
                        })


                        const datosInit = {
                            nombreDocumento: `Compra: ${datoInit.nombreDocumento}`,

                            cabecera1: {
                                visible: true
                            },
                            titulo: {
                                visible: true,
                                posicion: "center",
                                texto: "Compras",
                                TamanoLetras: 14,
                                color: "#7B7B7B",
                            },
                            cabecera2: {
                                visible: true,
                                datos: [
                                    { texto: "# " + datoInit.numeroDocumento, TamanoLetras: 14, color: "#FF0000" },
                                    { texto: "Fecha Creacion: " + FuncionesGenerales.formatoFecha(datoInit.fechaCreacion, 1) },
                                    { texto: "Hora Creacion: " + FuncionesGenerales.formatoFecha(datoInit.fechaCreacion, 2).replace(/\u00a0/g, "").replace(/\u202f/g, "") },
                                    { texto: "Empleado: " + 'Nombre Apellido' },
                                    { texto: "Autorizado: " + 'Nombre Apellido' }
                                ]
                            },
                            sub1: {
                                visible: true,
                                datos: [
                                    { texto: "Area: " + datoInit.area.nombre },
                                    { texto: "Moneda Negociacion: " + datoInit.moneda.nombre },
                                    { texto: "Condicion Pago: " + datoInit.condicionPago.descripcion },
                                    { texto: "Proveedor: " + datoInit.proveedor.tipoDocumento.abreviatura + '-' + datoInit.proveedor.documento + ' ' + datoInit.proveedor.razonSocial },
                                    { texto: "Estatus:" + datoInit.estatus.descripcion },
                                ]
                            },
                            sub2: {
                                visible: true,
                                datos: [
                                    { texto: "Numero Factura: " + datoInit.numeroFactura },
                                    { texto: "Numero Control: " + datoInit.numeroControl },
                                    { texto: "Fecha Factura: " + FuncionesGenerales.formatoFecha(datoInit.fechaFactura, 1) }
                                ]
                            },
                            cuerpo1: {
                                visible: true,
                                datos: [
                                    {
                                        titulo: { texto: "Detalles", TamanoLetras: 12, },
                                        styles:   {fontSize: 7,},
                                        columnStyles: {

                                            3: {
                                                halign: 'right'
                                            }
                                        },
                                        tablas: {
                                        },
                                        head: [[

                                            { content: "Barra", styles: { halign: 'left' } },
                                            { content: "Producto", styles: { halign: 'left' } },
                                            { content: "Lote", styles: { halign: 'left' } },
                                            { content: "Cant", styles: { halign: 'right' } },
                                            { content: "Precio", styles: { halign: 'right' } },
                                            { content: "Desc", styles: { halign: 'right' } },
                                            { content: "Iva", styles: { halign: 'right' } },
                                            { content: "Total", styles: { halign: 'right' } },
                                        ]],
                                        body: cuerpoTablaDetalles,

                                    },
                                    {
                                        titulo: {},
                                        styles:   {fontSize: 7,},
                                        columnStyles: {

                                            3: {
                                                halign: 'right'
                                            }
                                        },
                                        tablas: {
                                            cant: 2,
                                            id: 2
                                        },
                                        head: [],
                                        body: [
                                            [
                                                { content: "Sub total", styles: { halign: 'left' } },
                                                { content: datoInit.subTotal.toFixed(2), styles: { halign: 'right' } },
                                            ],
                                            [
                                                { content: "Descuento", styles: { halign: 'left' } },
                                                { content: datoInit.descuento.toFixed(2), styles: { halign: 'right' } },
                                            ],
                                            [
                                                { content: "Impuesto", styles: { halign: 'left' } },
                                                { content: datoInit.impuesto.toFixed(2), styles: { halign: 'right' } },
                                            ],
                                            [
                                                { content: "Total", styles: { halign: 'left' } },
                                                { content: datoInit.total.toFixed(2), styles: { halign: 'right' } },
                                            ]
                                        ],

                                    },
                                ]
                            }
                        }




                        pdfRef.value.generarPDF(datosInit)


                    }
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    cargandoDatos.value = false
                })


        }

        return {
            GetPdf,
            cargandoDatos,
            pdfRef,
            icons: {
                mdiFilePdfBox,
            }
        }
    },
}
</script>